.result_section {
  padding-top: 80px;
  padding-bottom: 60px;

  @include respond-to(phone) {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  &__card {
    background-color: #fff;
    display: flex;
    align-items: center;
    padding-left: 60px;
    position: relative;

    @include respond-to(tablet) {
      padding-left: 40px;
    }

    @include respond-to(tablet-mini) {
      flex-direction: column;
      padding: 40px 40px 0;
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid #e0e0e0;
      border-radius: 16px;
      left: 0;
      top: 0;
    }

    &__content {
      max-width: 446px;
      flex-shrink: 0;
      margin-right: 12px;

      &__title {
        font-family: 'Montserrat', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 38px;
        margin-bottom: 16px;
        color: #181818;

        @include respond-to(tablet) {
          font-size: 24px;
        }

        @include respond-to(tablet-mini) {
          text-align: center;
          line-height: 1.12;
        }
      }

      &__text {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #747474;

        @include respond-to(tablet-mini) {
          text-align: center;
        }
      }
    }

    &__img {
    }
  }
}
