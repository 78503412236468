.solutions_integration {
  &__inner {
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @include respond-to(mini) {
      flex-direction: column;
    }

    &__card {
      padding: 50px 30px 50px 60px;

      @include respond-to(tablet-small) {
        padding: 30px 20px 30px 40px;
      }

      @include respond-to(phone) {
        padding: 26px 16px 26px 30px;
      }

      &__title {
        font-weight: 800;
        font-size: 32px;
        line-height: 38px;
        color: #181818;
        margin-bottom: 16px;

        @include respond-to(tablet-small) {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 12px;
        }

        @include respond-to(phone) {
          font-size: 20px;
          line-height: 25px;
          margin-bottom: 10px;
        }

        @include respond-to(mini) {
          text-align: center;
        }
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #747474;

        @include respond-to(phone) {
          font-size: 14px;
          line-height: 22px;
        }

        @include respond-to(mini) {
          text-align: center;
        }
      }
    }

    &__img {
      flex-shrink: 0;

      @include respond-to(tablet-small) {
        width: 200px;
      }

      @include respond-to(phone) {
        width: 230px;
      }

      @include respond-to(mini) {
        order: -1;
        width: 100%;
      }

      picture,
      img {
        height: 100%;
        object-fit: cover;

        @include respond-to(mini) {
          width: 100%;
        }
      }
    }
  }
}
