.contacts {
  border-radius: 16px;
  border: 1px solid #fff;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__item {
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &__title {
      color: $white;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 8px;
    }

    &__link {
      display: flex;
      justify-content: center;

      &__img {
        margin-right: 12px;

        @include respond-to(micro) {
          margin-right: 0;
          margin-bottom: 12px;
        }
      }

      a {
        color: $white;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 1.2;
        display: flex;
        align-items: center;

        @include respond-to(phone) {
          font-size: 24px;
        }

        @include respond-to(mini) {
          font-size: 18px;
        }

        @include respond-to(micro) {
          flex-direction: column;
        }

        span {
          transition: 0.25s linear;
          opacity: 1;
        }

        &:hover {
          span {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
