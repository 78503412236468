.tr_features {
  margin-bottom: 120px;

  @include respond-to(tablet) {
    margin-bottom: 80px;
  }

  @include respond-to(phone) {
    margin-bottom: 60px;
  }

  &__inner {
    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 40px;
      padding-top: 84px;
      margin-left: auto;
      margin-right: auto;
      gap: 16px 20px;

      @include respond-to(tablet) {
        gap: 12px 14px;
      }

      @include respond-to(phone) {
        padding-top: 72px;
        margin-bottom: 30px;
        gap: 10px 12px;
      }

      &__button {
        color: #b4b8c1;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        padding: 16px 20px;
        transition: color 0.25s linear, box-shadow 0.25s linear;

        @include respond-to(tablet) {
          padding: 10px 22px;
        }

        @include respond-to(phone) {
          padding: 6px 12px;
          font-size: 14px;
          line-height: 20px;
        }

        &:hover {
          color: #151836;
        }

        &_active {
          color: #151836;
          box-shadow: 0 4px 0 0 #4700bb;
          transition: color 0.25s linear, box-shadow 0.25s linear;

          @include respond-to(phone) {
            box-shadow: 0 2px 0 0 #4700bb;
          }
        }
      }
    }

    &__images {
      position: relative;
      width: 100%;

      &__image {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.25s linear;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond-to(tablet-small) {
          flex-direction: column;
        }

        &__img {
          @include respond-to(tablet-small) {
            width: 100%;
            max-width: 400px;
          }
        }

        &__content {
          width: 100%;
          max-width: 538px;
          margin-left: 60px;

          @include respond-to(tablet-small) {
            margin-left: 0;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          &__title {
            color: #151836;
            font-size: 32px;
            font-weight: 700;
            line-height: 38px;
            margin-bottom: 16px;

            @include respond-to(tablet-small) {
              font-size: 24px;
              line-height: 30px;
              margin-bottom: 16px;
              text-align: center;
            }

            @include respond-to(phone) {
              font-size: 20px;
              line-height: 25px;
            }
          }

          &__text {
            color: #747474;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 36px;

            @include respond-to(tablet-small) {
              text-align: center;
            }

            @include respond-to(phone) {
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 20px;
            }
          }

          &__button {
            background-image: linear-gradient(92deg, #951bcc -0.53%, #4700bb 100%);
            padding: 14px 20px 14px 32px;
            border-radius: 8px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #fff;
            text-align: center;
            cursor: pointer;
            transition: background-position 0.25s linear;
            background-size: 300% 300%;
            outline: none;
            min-width: 180px;
            border: 1px solid transparent;
            display: inline-flex;
            align-items: center;

            @include respond-to(tablet) {
              padding: 14px 30px;
            }

            @include respond-to(phone) {
              padding: 12px 24px;
              font-size: 14px;
              line-height: 18px;
              display: flex;
              justify-content: center;
            }

            span {
              margin-right: 10px;
            }

            &:hover {
              background-position: 100% 100%;
            }

            &:disabled {
              opacity: 0.7;
              cursor: not-allowed;

              &:hover {
                background-position: 0 0;
              }
            }
          }
        }

        &_active {
          opacity: 1;
          visibility: visible;
          position: relative;
        }
      }
    }
  }
}
