.block_try {
  background-color: $dark;
  display: flex;
  margin-bottom: 80px;

  @include respond-to(tablet) {
    margin-bottom: 60px;
  }

  @include respond-to(phone) {
    margin-bottom: 40px;
  }

  &__img {
    @include background-image('try-free/CTA-img');
    width: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;

    @include respond-to(phone) {
      display: none;
    }
  }

  &__block {
    padding: 90px 20px 90px 48px;
    max-width: 582px;
    width: 100%;

    @include respond-to(phone) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 70px 20px;
    }

    &__title {
      font-size: 24px;
      font-weight: 800;
      line-height: 32px;
      color: $white;
      margin-bottom: 24px;
      max-width: 410px;
      width: 100%;

      @include respond-to(phone) {
        text-align: center;
      }
    }
  }
}
