.solutions_embed {
  padding-top: 40px;
  padding-bottom: 40px;

  .main-page & {
    padding-top: 120px;
  }

  @include respond-to(phone) {
    padding-top: 20px;
    padding-bottom: 20px;

    .main-page & {
      padding-top: 60px;
    }
  }

  .card-info {
    p {
      max-width: 632px;
    }
  }
}
