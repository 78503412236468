.block_platform {
  position: relative;
  background-color: #1d232a;
  padding: 48px 60px;
  margin-bottom: 120px;

  @include respond-to(tablet) {
    padding: 40px;
    margin-bottom: 80px;
  }

  @include respond-to(phone) {
    padding: 32px 30px;
    margin-bottom: 60px;
  }

  &__image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;

    @include respond-to(desktop-small) {
      opacity: 0.3;
    }

    @include respond-to(phone) {
      background-size: cover;
    }

    &_left {
      left: 0;
      @include background-image('platform-function/cta_bg-left');
      background-position: left center;

      @include respond-to(tablet-large) {
        transform: translateX(-16%);
      }

      @include respond-to(tablet) {
        transform: translateX(-20%);
      }

      @include respond-to(phone) {
        background-position: right center;
        transform: translateX(-60%);
      }

      @include respond-to(mini) {
        transform: translateX(-80%);
      }
    }

    &_right {
      right: 0;
      @include background-image('platform-function/cta_bg-right');
      background-position: right center;

      @include respond-to(tablet-large) {
        transform: translateX(16%);
      }

      @include respond-to(tablet) {
        transform: translateX(20%);
      }

      @include respond-to(phone) {
        background-position: left center;
        transform: translateX(60%);
      }

      @include respond-to(mini) {
        transform: translateX(80%);
      }
    }
  }

  &__inner {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to(mini) {
      min-width: auto;
    }

    &__title {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.3;
      text-align: center;
      color: $white;
      margin-bottom: 24px;

      @include respond-to(tablet) {
        font-size: 18px;
        margin-bottom: 20px;
      }

      @include respond-to(tablet) {
        font-size: 16px;
      }
    }

    &__button {
      margin: 0 auto;
    }
  }
}
